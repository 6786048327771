
.ResmChartDAD {
	height: 100%;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding: 2px;
}

.ResmChartDAD > div {
	height: '100%';
	padding: 1px 0;
}

.ResmChartDAD > div > div {
	height: 100%;
	overflow-y: auto;
}

.ResmChartDAD svg {
	filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.1));
}

.ResmChartDAD .ResmChartAxis path,
.ResmChartDAD .ResmChartAxis line {
 	fill: none;
	stroke: #666;
	shape-rendering: crispEdges;
	stroke-width: 0.5px;
}

.ResmChartDAD .ResmChartAxis text {
	font-size: 8px;
	font-weight: 300;
    fill: #333;
	text-anchor: middle;
}

.ResmChartDAD .ResmChartDayLabel {
	font-size: 8px;
	font-weight: 300;
	fill: #333;
	text-anchor: start;
}

.ResmChartDAD .ResmChartActivityContainer {
	fill: transparent;
}

.ResmChartDAD .ResmChartActivityLabel {
	font-size: 8px;
    font-weight: 300;
	text-anchor: middle;
}

.ResmChartDAD .ResmChartActivityGroup-9 .ResmChartActivityLabel,
.ResmChartDAD .ResmChartActivityGroup-1 .ResmChartActivityLabel, 
.ResmChartDAD .ResmChartActivityGroup-0 .ResmChartActivityLabel {
	fill: #666;
}

.ResmChartDAD .ResmChartActivityGroup-3 .ResmChartActivityLabel,
.ResmChartDAD .ResmChartActivityGroup-2 .ResmChartActivityLabel {
	fill: #444;
}


.ResmChartDAD .ResmChartActivityCrew {
	fill: #fafa84;
}

.ResmChartDAD .ResmChartActivityCrewLine {
	fill: none;
	stroke: #666;
	shape-rendering: crispEdges;
	stroke-width: 0.5px;
}

.ResmChartDAD .ResmChartActivity-9 {			/* brak danych */
	fill: #f8f8f8;
}

.ResmChartDAD .ResmChartActivity-9.hover {
	fill: #ddd;
}

.ResmChartDAD .ResmChartActivity-3 {			/* prowadzenie */
	fill: #fa7070;
	rx: 1px;
}

.ResmChartDAD .ResmChartActivity-3.hover {
	fill: #f84545;
}

.ResmChartDAD .ResmChartActivity-2 { 		    /* praca */
    fill: #fac269;
	rx: 1px;
}

.ResmChartDAD .ResmChartActivity-2.hover {
	fill: #fbae34;
}

.ResmChartDAD .ResmChartActivity-1 {			/* dyspozycyjność */
	fill: #fafa84;
}
.ResmChartDAD .ResmChartActivity-1.hover {
	fill: #f6f640;
}

.ResmChartDAD .ResmChartActivity-0 {			/* postój */
	fill: #97ddfa;
}

.ResmChartDAD .ResmChartActivity-0.hover {
	fill: #2abcf8;
}

.ResmChartDAD .ResmChartActivity-weeklyRestThisWeek {
	fill: #00b341;
}

.ResmChartDAD .ResmChartActivity-weeklyRestThisWeek.hover {
	fill: #018a11;
}

.ResmChartDAD .ResmChartPlace, 
.ResmChartDAD .ResmChartSpecificCondition, 
.ResmChartDAD .ResmChartDailyDrivingTimeStart {
	fill: #333;
}

.ResmChartDAD .ResmChartPlace line, 
.ResmChartDAD .ResmChartSpecificCondition line, 
.ResmChartDAD .ResmChartDailyDrivingTimeStart line {
	stroke: #333;
	shape-rendering: crispEdges;
    stroke-width: 0.5px;
}

.ResmChartDAD .ResmChartPlace text, 
.ResmChartDAD .ResmChartSpecificCondition text, 
.ResmChartDAD .ResmChartDailyDrivingTimeStart text {
	font-size: 7px;
	fill: #333;
	font-weight: 500;
}

.ResmChartDAD .ResmChartViolationLine-undefined {
	fill: yellow;
}

.ResmChartDAD .ResmChartViolationLine-dailyRest, 
.ResmChartDAD .ResmChartViolationLine-weeklyRest {
	fill: green;
}

.ResmChartDAD .ResmChartViolationLine-continuousDriving,
.ResmChartDAD .ResmChartViolationLine-dailyDriving,
.ResmChartDAD .ResmChartViolationLine-weeklyDriving, 
.ResmChartDAD .ResmChartViolationLine-twoWeeklyDriving {
	fill: red;
}

.ResmChartDAD .ResmChartViolationIcon {
	fill: red;
	cursor: pointer;
}